<template>
    <b-container>
        <b-row>
            <b-col class="startSell">
                <div class="title"><h1>{{data.eventTahunanTitle}}</h1></div>
            </b-col>
        </b-row>

        <div class="for_desktop event-tahunan">
        <b-row>
          <b-col cols="1" align="center"></b-col>
          <b-col cols="10" align="center">
            <b-carousel id="carousel-1" v-model="slide" :interval="3000" controls background="#ababab" style="text-shadow: 1px 1px 2px #333;">
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_1.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_2.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_3.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_4.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_5.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_6.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_7.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_8.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_9.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_10.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_11.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_12.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_13.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_14.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_15.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_16.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_17.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_18.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_19.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_20.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_21.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_22.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_23.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_24.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_25.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_26.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_27.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_28.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_29.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_30.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_31.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_32.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_33.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_34.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_35.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_36.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_37.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_38.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_39.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_40.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_41.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_42.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_43.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_44.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_45.JPG"></b-carousel-slide>  
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft1.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft2.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft3.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft4.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft5.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft6.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft8.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft10.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft12.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft13.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft14.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft15.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft16.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft17.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft18.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft19.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft20.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft21.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft23.jpeg"></b-carousel-slide>      
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft25.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft26.jpeg"></b-carousel-slide>  
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft28.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft29.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft30.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft31.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft32.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft33.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft35.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft38.jpeg"></b-carousel-slide>
            </b-carousel>
          </b-col>
          <b-col cols="1" align="center"></b-col>
        </b-row>
        </div>

        <div class="for_tablet event-tahunan">
        <b-row>
          <b-col cols="1" align="center"></b-col>
          <b-col cols="10" align="center">
            <b-carousel id="carousel-1" v-model="slide" :interval="3000" controls background="#ababab" style="text-shadow: 1px 1px 2px #333;">
                            <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_1.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_2.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_3.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_4.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_5.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_6.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_7.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_8.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_9.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_10.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_11.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_12.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_13.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_14.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_15.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_16.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_17.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_18.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_19.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_20.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_21.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_22.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_23.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_24.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_25.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_26.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_27.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_28.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_29.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_30.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_31.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_32.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_33.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_34.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_35.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_36.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_37.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_38.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_39.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_40.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_41.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_42.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_43.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_44.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_45.JPG"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft1.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft2.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft3.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft4.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft5.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft6.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft8.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft10.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft12.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft13.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft14.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft15.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft16.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft17.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft18.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft19.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft20.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft21.jpeg"></b-carousel-slide> 
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft23.jpeg"></b-carousel-slide> 
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft25.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft26.jpeg"></b-carousel-slide>  
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft28.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft29.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft30.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft31.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft32.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft33.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft35.jpeg"></b-carousel-slide>       
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/eft38.jpeg"></b-carousel-slide>
            </b-carousel>
          </b-col>
          <b-col cols="1" align="center"></b-col>
        </b-row>
        </div>

        <div class="for_mobile event-tahunan">
        <b-row>
          <b-col cols="1" align="center"></b-col>
          <b-col cols="12" align="center">
            <img src="../../assets/images/event/1.jpeg" width="100%" @click="clickEvent('all')">

            <transition name="fade">
              <div v-if="showEvent == 1" class="event">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_1.JPG" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_2.JPG" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_3.JPG" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_4.JPG" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_5.JPG" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_6.JPG" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_7.JPG" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_8.JPG" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_9.JPG" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_10.JPG" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_11.JPG" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_12.JPG" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_13.JPG" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_14.JPG" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_15.JPG" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_16.JPG" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_17.JPG" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_18.JPG" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_19.JPG" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_20.JPG" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_21.JPG" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_22.JPG" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_23.JPG" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_24.JPG" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_25.JPG" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_26.JPG" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_27.JPG" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_28.JPG" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_29.JPG" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_30.JPG" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_31.JPG" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_32.JPG" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_33.JPG" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_34.JPG" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_35.JPG" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_36.JPG" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_37.JPG" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_38.JPG" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_39.JPG" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_40.JPG" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_41.JPG" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_42.JPG" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_43.JPG" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_44.JPG" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/bagd21_45.JPG" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/eft1.jpeg" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/eft2.jpeg" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/eft3.jpeg" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/eft4.jpeg" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/eft5.jpeg" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/eft6.jpeg" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/eft8.jpeg" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/eft10.jpeg" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/eft12.jpeg" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/eft13.jpeg" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/eft14.jpeg" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/eft15.jpeg" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/eft16.jpeg" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/eft17.jpeg" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/eft18.jpeg" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/eft19.jpeg" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/eft20.jpeg" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/eft21.jpeg" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/eft23.jpeg" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/eft25.jpeg" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/eft26.jpeg" width="100%"> 
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/eft28.jpeg" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/eft29.jpeg" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/eft30.jpeg" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/eft31.jpeg" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/eft32.jpeg" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/eft33.jpeg" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/eft34.jpeg" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/eft35.jpeg" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/eft36.jpeg" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/eft38.jpeg" width="100%">
              </div>
            </transition>

          </b-col>
          <b-col cols="1" align="center"></b-col>
        </b-row>
        </div>

               <b-row>
            <b-col class="startSell">
                <div class="title"><h1>{{data.charityTitle}}</h1></div>
            </b-col>
        </b-row>

        <div class="for_desktop event-tahunan">
        <b-row>
          <b-col cols="1" align="center"></b-col>
          <b-col cols="10" align="center">
            <b-carousel id="carousel-1" v-model="slide" :interval="3000" controls background="#ababab" style="text-shadow: 1px 1px 2px #333;">
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/chr1-cover.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/chr4.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/chr1.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/chr2.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/chr3.jpeg"></b-carousel-slide>
            </b-carousel>
          </b-col>
          <b-col cols="1" align="center"></b-col>
        </b-row>
        </div>

        <div class="for_tablet event-tahunan">
        <b-row>
          <b-col cols="1" align="center"></b-col>
          <b-col cols="10" align="center">
            <b-carousel id="carousel-1" v-model="slide" :interval="3000" controls background="#ababab" style="text-shadow: 1px 1px 2px #333;">
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/chr1-cover.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/chr4.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/chr1.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/chr2.jpeg"></b-carousel-slide>
              <b-carousel-slide img-src="https://api.bisnisonlinebgs.com/uploads/images/event/chr3.jpeg"></b-carousel-slide>
            </b-carousel>
          </b-col>
          <b-col cols="1" align="center"></b-col>
        </b-row>
        </div>

        <div class="for_mobile event-tahunan">
        <b-row>
          <b-col cols="1" align="center"></b-col>
          <b-col cols="12" align="center">
            <img src="https://api.bisnisonlinebgs.com/uploads/images/event/chr1-cover-mobile.jpeg" width="100%" @click="clickCharity('all')">

            <transition name="fade">
              <div v-if="showCharity == 1" class="event">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/chr4.jpeg" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/chr1.jpeg" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/chr2.jpeg" width="100%">
                <img src="https://api.bisnisonlinebgs.com/uploads/images/event/chr3.jpeg" width="100%">
              </div>
            </transition>

          </b-col>
          <b-col cols="1" align="center"></b-col>
        </b-row>
        </div>



    </b-container>
</template>
<script>
import axios from 'axios'
export default {
    data(){
        return{
            bahasa:'id',
            data:{
              eventTahunanTitle:null,
              charityTitle:null
            },
            showEvent:0,
            showCharity:0
        }
    },
    mounted(){
        this.bahasa = localStorage.getItem('bahasa')
        axios.get(`../../lang_${this.bahasa}.json`).then(response => {
        // console.log(response.data.data[0].general[0].eventTahunanTitle)
        this.data.eventTahunanTitle = response.data.data[0].general[0].eventTahunanTitle
        this.data.charityTitle = response.data.data[0].general[0].charityTitle
      })
    },
    methods:{
    clickEvent(){
        if(this.showEvent == 0){
            this.showEvent = 1
        } else {
             this.showEvent = 0
        }
            
      },

      clickCharity(){
        if(this.showCharity == 0){
            this.showCharity = 1
        } else {
             this.showCharity = 0
        }
      }
    }
}
</script>